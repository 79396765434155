/* Text colors */
$customOrange: #e67817;
$grey:          #767676;
$darkGrey:      #494949;

/*********************** Menu navigation **************************************/
.main-navigation {
  display: none;
}

.menu {
  display: block;
}
/* Mobile Styles & Tablet Styles - hide main menu & show burger menu */
@media only screen and (max-width: 1327px) {

  div.main-navigation {
    display: none;
  }
}

/* Destkop menu + iPod Pro (Rotated 1366 x 1024) - hide burger menu & show main menu */
@media only screen and (min-width: 1328px) {

  div.menu {
    display: none;
  }
}

/************************* General styles  ************************************/
* {
   /* This makes sure that the padding and border are included in the total width and height of the elements. */
   box-sizing: border-box;
   margin: 0;
   padding: 0;

   outline: none;
}

.center {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 50% !important;

  float: none !important;
}

.left {
  float: left;
}

.right {
  float: right;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.indent {
  padding: 10px;
}

.indentTop10 {
  padding-top: 10px;
}

.indentTop {
  padding-top: 110px;
}

body {
  font-family: 'oswald-regular-webfont';
  box-sizing: border-box;
}

.alignLeft {
  text-align: left;
  padding-left: 10px;
}

h3, .h3 {
	font-size: 1.2rem;
  font-weight: 700;
  line-height: 2.125;
  text-transform: uppercase;
}

/************************* Header   *******************************************/
.header__container {
  max-width: 1470px;
  width: 100%;
  position: relative;
  display: table;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;

  background: #222;
}

.header__column {
  display: table-cell;
}

/* Main Menu */

/* Opened submenu*/
.dd-items-left {
  background: #383838;
  border-radius: 25px;
  padding: 10px;
  margin-right: 15px;
}
.dd-menu-items li {
  padding-left: 20px;
  display: block; /* each item on separate line */
}
.dd-menu-items ul {
  top: 0px !important; /* correct position of submenu items */
}

.dd-menu {
  float: left; /* Location is in one block so other menu items like 'BRANKARSKY TRENINK' can not be unde it */
}

header {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 10;
  width: 100%;
  max-width: 1470px;
}

header section {
  max-width: 1470px;
  margin: auto;
  background: #fff;
}

header ul {
  position: relative;
  top: -20px;
  left: 10px;
}

@media (max-width: 1515px) {
  header section {
    max-width: 100%;
  }
}

header section img {
    max-width: 100px;
    max-height: 55px;
    transform: translate(0px, 10px);
    cursor: pointer;
}

header h1 {
  padding-left: 20px;
  margin-top: 15px;
  font-size: 1.6em;
  text-transform: uppercase;
}

.header {
	color: white;
  height: 75px;
}

button {
  -webkit-appearance: none;
          appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
         filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
          transition: all .5s ease;
  border: none;
  background: #e67817;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: cursor;
  color: white;
}

button:hover {
  color: #FFFFFF;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

button a {
  color: #FFFFFF;
  text-decoration: none;
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

#profile-detail {
  display: none;
}

.profileHeadlineName {
  padding-left: 7px;
}

/* Desktop Sliders */
.slider-wrapper {
  position: relative;
  height: 65vh; /* Total height of Sliders  (sliders originally 40vh)*/
  overflow: hidden;
  max-width: 1470px;
}

.slide {
  height: 65vh; /* The smoller number means Zoom IN, bigger Zoom OUT for picture (sliders originally 40vh)*/
  background-size: cover !important;
}

/* Desktop Large: */
@media (min-height: 1025px) and (max-height: 4000px)  and (min-width: 1921px) and (max-width: 4000px) {
  .slider-wrapper, .slide {
    height: 61vh;
  }
    /* Slide main and top picture - */
  .slide {
    height: 100vh; /* full screen picture */
  }

  /* Slide try training */
  .slide-try-training {
    height: 70vh !important; /* full screen picture */
  }

  /* Slide about us - */
  .slide-about {
    height: 90vh !important;
  }

  .profile-slider .slide {
    height: 80vh !important;
  }

  .profile-slider {
    height: 62vh !important;
    width: 50%;
    float: left;
  }

  #profile-detail {
    width: 45%;
    float: left;
    display: inline-block;
    padding-left: 15px;


    text-align: justify;
    text-justify: inter-word;
  }

  #btn-profile-read-more {
    display: none;
  }

}
/*
Desktop stats 2022:

(width x height)
1366×768	22.6%
1920×1080	20.87%
1536×864	8.52%
1440×900	6.97%
1280×720	4.81%
1600×900	4.1%
1280×800	2.95%
1280×1024	2.65%
1024×768	2.59%
768×1024	2.47% TODO bottom navigation broken
*/

/* Desktop 1366×768	22.6% */
@media (min-height: 768px) and (max-height: 768px) and (min-width: 1366px) and (max-width: 1366px) {
  .slider-wrapper, .slide {
    height: 61vh;
  }
    /* Slide main and top picture - */
  .slide {
    height: 94vh; /* full screen picture */
  }

  /* Slide try training */
  .slide-try-training {
    height: 70vh !important; /* full screen picture */
  }

  /* Slide about us - */
  .slide-about {
    height: 115vh !important;
  }

  .profile-slider .slide {
    height: 80vh !important;
  }

  .profile-slider {
    height: 62vh !important;
    width: 50%;
    float: left;
  }

  #profile-detail {
    width: 45%;
    float: left;
    display: inline-block;
    padding-left: 15px;


    text-align: justify;
    text-justify: inter-word;
  }

  #btn-profile-read-more {
    display: none;
  }
}

/* Desktop 1920×1080	20.87% */
@media (min-height: 1080px) and (max-height: 1080px) and (min-width: 1920px) and (max-width: 1920px) {
  .slider-wrapper, .slide {
    height: 61vh;
  }
    /* Slide main and top picture - */
  .slide {
    height: 89vh; /* full screen picture */
  }

  /* Slide try training  - */
  .slide-try-training {
    height: 65vh !important; /* Family member + try trainging = full screen */
  }

  /* Slide about us - */
  .slide-about {
    height: 90vh !important; /* almost full screen picture */
  }


  .profile-slider .slide {
    height: 80vh !important;
  }

  .profile-slider {
    height: 62vh !important;
    width: 50%;
    float: left;
  }

  #profile-detail {
    width: 45%;
    float: left;
    display: inline-block;
    padding-left: 15px;


    text-align: justify;
    text-justify: inter-word;
  }

  #btn-profile-read-more {
    display: none;
  }
}

/* Desktop 1536×864	8.52% */
@media (min-height: 864px) and (max-height: 864px) and (min-width: 1536px) and (max-width: 1536px) {
  .slider-wrapper, .slide {
    height: 61vh;
  }
    /* Slide main and top picture - */
  .slide {
    height: 100vh; /* full screen picture */
  }

  /* Slide try training */
  .slide-try-training {
    height: 75vh !important; /* full screen picture */
  }

  /* Slide about us - */
  .slide-about {
    height: 93vh !important;
  }

  .profile-slider .slide {
    height: 80vh !important;
  }

  .profile-slider {
    height: 62vh !important;
    width: 50%;
    float: left;
  }

  #profile-detail {
    width: 45%;
    float: left;
    display: inline-block;
    padding-left: 15px;


    text-align: justify;
    text-justify: inter-word;
  }

  #btn-profile-read-more {
    display: none;
  }
}

/* Desktop 1440×900	6.97%
           1600×900	4.1%
- values are the same as 1536×864 */
@media (min-height: 900px) and (max-height: 900px) and (min-width: 1440px) and (max-width: 1600px) {
  .slider-wrapper, .slide {
    height: 61vh;
  }
    /* Slide main and top picture - */
  .slide {
    height: 100vh; /* full screen picture */
  }

  /* Slide try training */
  .slide-try-training {
    height: 75vh !important; /* full screen picture */
  }

  /* Slide about us - */
  .slide-about {
    height: 93vh !important;
  }

  .profile-slider .slide {
    height: 80vh !important;
  }

  .profile-slider {
    height: 62vh !important;
    width: 50%;
    float: left;
  }

  #profile-detail {
    width: 45%;
    float: left;
    display: inline-block;
    padding-left: 15px;


    text-align: justify;
    text-justify: inter-word;
  }

  #btn-profile-read-more {
    display: none;
  }
}

/* Desktop 1280×720	4.81%
           1024×768	2.59%
           1280×800	2.95%
*/
@media (min-height: 720px) and (max-height: 800px) and (min-width: 1024px) and (max-width: 1280px) {
  .slider-wrapper, .slide {
    height: 61vh;
  }
    /* Slide main and top picture - */
  .slide {
    height: 100vh; /* full screen picture */
  }

  /* Slide try training */
  .slide-try-training {
    height: 75vh !important; /* full screen picture */
  }

  /* Slide about us - */
  .slide-about {
    height: 93vh !important;
  }

  .profile-slider .slide {
    height: 80vh !important;
  }

  .profile-slider {
    height: 62vh !important;
    width: 50%;
    float: left;
  }

  #profile-detail {
    width: 45%;
    float: left;
    display: inline-block;
    padding-left: 15px;


    text-align: justify;
    text-justify: inter-word;
  }

  #btn-profile-read-more {
    display: none;
  }
}

/* Desktop  (1400 x 1050), (1680 x 1050)
@media (min-height: 1050px) and (max-height: 1079px)
        and (min-width: 1400px) {
  .slider-wrapper, .slide {
    height: 69vh;
  }
  .slide {
    height: 71vh;
  }

  .news-slider {
    height: 57vh !important;
  }
  .news-slider .slide {
    height: 44vh !important;
  }

  .profile-slider .slide {
    height: 80vh !important;
  }

  .profile-slider {
    height: 55vh !important;
    width: 50%;
    float: left;
  }

  #profile-detail {
    width: 45%;
    float: left;
    display: inline-block;
    padding-left: 15px;


    text-align: justify;
    text-justify: inter-word;
  }

  #btn-profile-read-more {
    display: none;
  }
}
 */

/* Desktop (1280 x 960)
@media (min-height: 960px) and (max-height: 1049px)
       and (min-width: 1280px) {
  .slider-wrapper, .slide {
    height: 52vh;
  }
  .slide {
    height: 71vh;
  }

  .news-slider {
    height: 50vh !important;
  }
  .news-slider .slide {
    height: 52vh !important;
  }

  .profile-slider .slide {
    height: 80vh !important;
  }

  .profile-slider {
    height: 55vh !important;
    width: 50%;
    float: left;
  }

  #profile-detail {
    width: 45%;
    float: left;
    display: inline-block;
    padding-left: 15px;


    text-align: justify;
    text-justify: inter-word;
  }

  #btn-profile-read-more {
    display: none;
  }
}
 */

/* Desktop (1440 x 900), (1600 x 900)
@media (min-height: 900px) and (max-height: 959px)
       and (min-width: 1440px) {
  .slider-wrapper, .slide {
    height: 54vh;
  }
  .slide {
    height: 85vh;
  }

  .news-slider {
    height: 50vh !important;
  }
  .news-slider .slide {
    height: 52vh !important;
  }

  .profile-slider .slide {
    height: 80vh !important;
  }

  .profile-slider {
    height: 55vh !important;
    width: 50%;
    float: left;
  }

  #profile-detail {
    width: 45%;
    float: left;
    display: inline-block;
    padding-left: 15px;


    text-align: justify;
    text-justify: inter-word;
  }

  #btn-profile-read-more {
    display: none;
  }
}
*/

/* Desktop (1280 x 800)
@media (min-height: 800px) and (max-height: 899px)
       and (min-width: 1280px) {
  .slider-wrapper, .slide {
    height: 55vh;
  }
  .slide {
    height: 84vh;
  }

  .news-slider {
    height: 54vh !important;
  }
  .news-slider .slide {
    height: 56vh !important;
  }

  .profile-slider .slide {
    height: 80vh !important;
  }

  .profile-slider {
    height: 55vh !important;
    width: 50%;
    float: left;
  }

  #profile-detail {
    width: 45%;
    float: left;
    display: inline-block;
    padding-left: 15px;


    text-align: justify;
    text-justify: inter-word;
  }

  #btn-profile-read-more {
    display: none;
  }
}
 */

/* Desktop (1280 x 768), (1360 x 768), (1366 x 768)
@media (min-height: 768px) and (max-height: 799px)
       and (min-width: 1280px) {
  .slider-wrapper, .slide {
    height: 61vh;
  }
  .slide {
    height: 94vh;
  }

  .news-slider {
    height: 55vh !important;
  }
  .news-slider .slide {
    height: 55vh !important;
  }

  .profile-slider .slide {
    height: 80vh !important;
  }

  .profile-slider {
    height: 62vh !important;
    width: 50%;
    float: left;
  }

  #profile-detail {
    width: 45%;
    float: left;
    display: inline-block;
    padding-left: 15px;


    text-align: justify;
    text-justify: inter-word;
  }

  #btn-profile-read-more {
    display: none;
  }
}
*/

/* NOT SUPPORTED: Desktop (1280 x 720), (1280 x 600), (1152 x 864), (800 x 600) */

/* iPad Pro (1024 x 1366) (vs Desktop (1500 x 1024)) */
@media (min-height: 1025px) and  (max-height: 1366px)
       and (max-width: 1024px) {
  .news-slider .slide {
    height: 95vh !important;
  }

  .news-slider {
    height: 57vh !important;
  }

  .profile-slider .slide {
    height: 80vh !important;
  }

  .profile-slider {
    height: 55vh !important;
    width: 50%;
    float: left;
  }

  #profile-detail {
    width: 45%;
    float: left;
    display: inline-block;
    padding-left: 15px;


    text-align: justify;
    text-justify: inter-word;
  }

  #btn-profile-read-more {
    display: none;
  }

}

/* Ipad (768 x 1024) */
@media (min-height: 668px) and (max-height: 1024px)
      and (max-width: 1023px) /* not rotated */
      and (min-width: 768px) /* not iPhone X (375 x 812) */ {
  .slider-wrapper, .slide {
    height: calc(60vh - 75px );
  }

  .news-slider {
    height: 62vh !important;
  }

  .news-slider .slide {
    height: 95vh !important;
  }

  .profile-slider .slide {
    height: 65vh !important;
  }

  .profile-slider {
    height: 50vh !important;
  }
}

.profile-slider .slide::before {
  background: none !important; /* remove linear gradient */
}

/* Galaxy S5 (360 x 640), iPhone 5/SE (320 x 568), iPhone 6/7/8 (375 x 667), iPhone X (375 x 812)
   Pixel 2 XL (411 x 823)  */
@media (max-width: 414px) and (max-height: 823px) {
  .slider-wrapper, .slide {
    height: calc(80vh - 75px);
  }

  .news-slider {
    height: 80vh !important;
  }

  .news-slider .slide {
    height: 80vh !important;
  }

  .profile-slider .slide {
    height: 87vh !important;
  }

  .profile-slider {
    height: 87vh !important;
  }

  .profile-slider .inner {
    top: 74% !important;
  }
}

/** ************* Roteted devices **********************************/
/* iPhone 5/SE Rotated (568 x 320) !!!Rotated!!!  */
@media (max-width: 568px) and (max-height: 320px)  {

  .slider-wrapper, .slide {
    height: 90vh !important;
  }

  .profile-slider .slide {
    height: 150vh !important;
  }

  .profile-slider {
    height: 90vh !important;
  }

  .profile-slider .inner {
    top: 35% !important;
  }

}

/* Galaxy S5 (640 x 360), iPhone 6/7/8 (667 x 375) !!!Rotated!!!  */
@media (max-height: 420px) and (max-width: 800px)
        and (min-height: 321px) /* not Rotated iPhone 5/SE */ {
  .slider-wrapper, .slide {
    height: 80vh;
  }

  .profile-slider .slide {
    height: 150vh !important;
  }

  .profile-slider {
    height: 90vh !important;
  }

  .profile-slider .inner {
    top: 35% !important;
  }
}

/* iPhone X (812 x 375) !!!Rotated!!!  */
@media (max-height: 420px) and (max-width: 850px) {
  .slider-wrapper, .slide {
    height: 130vh !important;
  },


  .news-slider {
    height: 65vh !important;
  }

  .news-slider .slide {
    height: 75vh !important;
  }
  .news-slider .inner {
    top: 40% !important;
  }


  .profile-slider .slide {
    height: 180vh !important;
  }

  .profile-slider {
    height: 110vh !important;
  }

  .profile-slider .inner {
    top: 35% !important;
  }
}

/* iPad Pro (1366 x 1024) !!!Rotated!!!  */
/* Covered also desktop: 1280×1024	2.65% */
@media (max-width: 1366px) and (max-height: 1024px)
      and (min-height: 1024px)  /* not Desktop (1366 x 768) */
      and (min-width: 851px) { /* Why ???? */
  .slider-wrapper, .slide {
    height: calc(80vh - 75px);
  },


  .news-slider {
    height: 90vh !important;
  }

  .news-slider .slide {
    height: 65vh !important;
  }
  .news-slider .inner {
    top: 40% !important;
  }


  .profile-slider .slide {
    height: 110vh !important;
  }

  .profile-slider {
    height: 70vh !important;
  }

  .profile-slider .inner {
    top: 35% !important;
  }
}

/* iPad (1024 x 768) !!!Rotated!!!  */
@media (max-width: 1024px) and (max-height: 768px)
      and (min-width: 851px) {  /* Why ???? */
  .slider-wrapper, .slide {
    height: calc(80vh - 75px);
  },


  .news-slider {
    height: 90vh !important;
  }

  .news-slider .slide {
    height: 65vh !important;
  }
  .news-slider .inner {
    top: 40% !important;
  }


  .profile-slider .slide {
    height: 110vh !important;
  }

  .profile-slider {
    height: 70vh !important;
  }

  .profile-slider .inner {
    top: 35% !important;
  }
}

/******************** Sliders general****** **********************************/
.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    bottom: 0;
    left: 0;
}

.previousButton, .nextButton {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
		background: url('https://svgshare.com/i/Dab.svg') no-repeat center center / 16px;
		width: 32px;
		height: 32px;
		text-indent: -9999px;
		cursor: pointer;
}

.main-sliders .previousButton, .main-sliders .nextButton {
    display: none;
}

.previousButton:hover, .nextButton:hover {
  background: url('https://svgshare.com/i/Dab.svg') no-repeat center center / 16px;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
          transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: -10px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -10px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.slider-content .inner button{
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #FFFFFF;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #FFFFFF;
  font-size: 14px;
  display: block;
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-height: 500px) {
  .slider-content .inner h1 {
    font-size: 24px;
  }
}

@media (max-width: 320px) {
  .slider-content .inner h1 {
    font-size: 24px;
  }
}

/** Footer */
.footer {
	background: #2E2E2E;

	height: 61px;
	color: white;
  max-width: 1470px;
  float: left;
  width: 100%;
}
.footer a {
  color: gray;
  padding-left: 50px;
}
.social-svg-icon {
  fill: white !important;
}

.footerPartners {
  background: #2E2E2E;
  padding-top: 8px;
  padding-left: 10px;
}

.footerPartners span {
  color: #f9f9f9;
  font-size: 1.1rem;
  font-weight: 600;
}

.footer-copyright  {
	color: gray;
	width: 100%;
	text-align: center;
	background: #2E2E2E;
	height: 35px;
  padding-top: 10px;
}

.footer-copyright a {
	  color: gray;
    padding-left: 0px;
}

.content {
  max-width: 1470px;
  margin: auto;
}


/* Responsible design - Mobile styles as default  */
.page {

}

.section {
  height: 275px;
  justify-content: center;
  align-items: center;
}

.bottom-image-navigations-section {
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 15px;
}

.responsive {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    padding-right: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
}

.column-1,
.column-2,
.column-3 {
  width: 100%;
  padding-top: 10px;
}

/* Default - 4 images per row (Devices with large width screen) */
.navigation-image-column {
  width: 25%;
}

div.item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
}

.caption {
    display: block;
    font-weight: bold;
    font-size: 105%;
}


/* Mobile Styles */
/* Extra small mobiles e.g Galaxy Fold (280 x 653)*/
@media only screen and  (max-width: 350px) {
  .column-1,
  .column-2,
  .column-3 {
    width: 75%;
    padding-top: 10px;
  }

  .view img {
    width: 100%;
    padding-bottom: 10px;
  }

  /* Small devices - 1 image per row (8 rows in total) */
  .navigation-image-column {
    width: 100%;
  }

  /* Small devices - smaller images  */
  .family-member-navigations-section img {
    width: 120px !important;
    height: 120px !important;

  }

  .bottom-image-navigations-section img {
    width: 145px !important;
    height: 145px !important;
  }

  .ray-newland-quote-img {
    width: 80%;
  }
}

/* Medium small phones */
@media only screen and (min-width: 351px) and (max-width: 374px) {
  .column-1,
  .column-2,
  .column-3 {
    width: 75%;
    padding-top: 10px;
  }

  .view img {
    width: 100%;
    padding-bottom: 10px;
  }

  /* Small devices - 2 images per row (1 image in row is not enought, and 4 is too many) */
  .navigation-image-column {
    width: 42%;
  }

  /* Small devices - smaller images  */
  .family-member-navigations-section img {
    width: 120px !important;
    height: 120px !important;
  }

  .bottom-navigation-images img {
    width: 120px !important;
    height: 120px !important;
  }

  .ray-newland-quote-img {
    width: 80%;
  }
}

/* iPhone SE (375 x 667)*/
@media only screen and (min-width: 375px) and (max-width: 420px) {
  .column-1,
  .column-2,
  .column-3 {
    width: 75%;
    padding-top: 10px;
  }

  .view img {
    width: 100%;
    padding-bottom: 10px;
  }

  /* Small devices - 2 image per row */
  .navigation-image-column {
    width: 48%;
  }

  /* Small devices - smaller images  */
  .family-member-navigations-section img {
    width: 120px !important;
    height: 120px !important;
  }

  .bottom-image-navigations-section img {
    width: 120px !important;
    height: 120px !important;
  }

  .navigation-image-column button {
    padding-left: 5px;
    padding-right: 5px;
  }

  .ray-newland-quote-img {
    width: 80%;
  }
}

/* Tablet Styles */
@media only screen and (min-width: 421px) and (max-width: 580px) {
  .column-1,
  .column-2,
  .column-3 {
    width: 75%;
    padding-top: 10px;
  }

    /* Small devices - 2 images per row */
  .navigation-image-column {
    width: 50%;
  }

  .bottom-image-column img {
    width: 135px !important;
    height: 135px !important;
  }

  /* Small devices - smaller images  */
  .family-member-navigations-section img {
    width: 120px !important;
    height: 120px !important;
  }

  .view img {
    width: 60%;
    float: left;
    padding-right: 10px;
    margin-right: 20px;
    padding-bottom: 10px;
  }

  .ray-newland-quote-img {
    width: 60%;
  }
}

@media only screen and (min-width: 581px) and (max-width: 960px) {
  .column-1,
  .column-2,
  .column-3 {
    width: 75%;
    padding-top: 10px;
  }

    /* Small devices - 2 images per row */
  .navigation-image-column {
    width: 25%;
  }

  .bottom-image-column img {
    width: 135px !important;
    height: 135px !important;
  }

  /* Small devices - smaller images  */
  .family-member-navigations-section img {
    width: 120px !important;
    height: 120px !important;
  }

  .view img {
    width: 60%;
    float: left;
    padding-right: 10px;
    margin-right: 20px;
    padding-bottom: 10px;
  }

  .ray-newland-quote-img {
    width: 60%;
  }
}


/* Desktop Styles */
@media only screen and (min-width: 961px) {
  .page {
    margin: 0 auto;
  }
  .column-1,
  .column-2,
  .column-3 {
    width: 45%;
    padding-right: 15px;
    padding-top: 15px;
  }

  .header {
    height: 75px;
  }

  .view img {
    width: 50%;
    float: left;
    padding-right: 10px;
    margin-right: 20px;
    padding-bottom: 10px;
  }

  .ray-newland-quote-img {
    width: 50%;
  }
}

/* View */
.view  {
  background-color: #f9f9f9;

  margin: auto;
  background: #f9f9f9;
  color: black;

  float: left;
  padding-top: 59px;
  padding-left: 10px;
  padding-right: 10px;

  width: 100%;
}

.view h2 {
	text-align: center;
  color: #222;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 5px;
}

.view h4 {
	text-align: center;
  color: #e67817;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.view a {
  color: #007bff;
}

.view button a {
  color: white;
}

.textArticle ul {
  padding-top: 15px;
}

.triangle-li {
  list-style-image: url("../images/icons/li_black.png") !important;
}

.arrow-li {
  list-style-image: url("../images/icons/arrow_orange.png") !important;
}

.textArticle li {
  margin-left: 25px;
}

.speech {
  color: #e67817;
}

/* Try Training: */
.try-training {
  height: 500px;

  width: 100%;

}
.try-training-content {
  height: 50%;
  width: 100%;
  float: left;
  padding-bottom: 10px;
}
.try-training-content .headline {
  padding-left: 50px;
  padding-top: 10px;
}
.try-training-image {
  width: 100%;
  height: 80%;
  padding: 0px !important;
}
.try-training li {
  padding-bottom: 10px;
  padding-left: 5px;
}
.try-training ul {
  padding-bottom: 10px;
  padding-left: 20px;
}

.view p {
  padding: 10px;
}

/* Ray Newland */
.ray-newland {
    padding: 15px;
    text-align: justify;
}

.ray-newland p {
    padding-bottom: 20px;
}

.ray-newland-quote  {
  display: flex;
  flex-wrap: wrap;
}

/* Aboout us */
.about-us > h3 {
  padding-top: 25px;
  text-align: center;
  color: #e67817;
}

.about-us > p {
  padding-bottom: 20px;
  padding-left: 10px;
}

.about-us > img {
  max-width: 320px;
  text-align: right;
  float: left;
  padding-bottom: 20px;
  padding-right: 15px;
}

.slider-carier-info {
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
  color: white;
}
@media (max-width: 450px) {
    /* smaller displays show text bellow img, bigger displays floats text right to img */
    .about-us > img {
      width: 100% !important;
      max-width: 450px;
      padding-right: 15px;
      padding-left: 15px;
    }

}

@media (max-width: 380px) {
    /* smaller displays show 1 images in row (8 rows in total)*/
    .bottom-image-column {
      width: 75%;
      padding-top: 10px;
      padding-left: 10px;
    }
}

/* Views */

.orange {
  color: #e67817;
}
.basic-border {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

/* Text article */
.textArticle {
  text-align: justify; /* prety print text like in newspapers */
}

.photoGallery {
  width: 100% !important;
}

/* Training locatins */
.trainingLocationArticle {
  text-align: center;
}

.trainingLocationArticle h2 {
  color: #e67817;
  float: left;
}

.trainingLocationArticle > img {
  padding-top: 10px;
}

.trainingLocationArticle div {
  width: 100%;
  padding-bottom: 10px;
}

.trainingLocationArticle table {
  margin: auto;
}


/* Contacts */
.contactsTable {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  width: 100%;
  background: linear-gradient(to right, rgba(34,34,34,0.8), gray);
}

.contactName {
  font-family: "nudista-web";
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: 600;
  color: #eee;
  padding-right: 10px;
  padding-left: 5px;
  text-align: left;
}

.contactPhone {
  font-family: "nudista-web";
  font-size: 1.1rem;

  color: #dcdcdc;
  padding-right: 10px;
  text-align: center;
}

/* News */

.news {
  float: left;
}
.news > img {
  float: left;
  max-width: 300px;
  padding-left: 35px;
}

@media (max-width: 420px) {
    /* smaller displays show text bellow img, bigger displays floats text right to img */
    .news > img {
      width: 100% !important;
      max-width: 420px;
      padding-right: 15px;
      padding-left: 15px;
    }

    .news > p {
      padding-right: 15px;
      padding-left: 15px;
    }
}

.news h2 {
  float: left;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}

/* References */
.reference-quotes {
  background: #d7d7d7;
  margin: 25px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0.5rem 0.5rem 2rem 0px;
}

.reference-quotes > h4 {
 color: black;
 text-align: left;
 padding-left: 0px;
 padding-bottom: 10px;
}

.reference-images > img {
  max-width: 300px;
  padding-left: 35px;
}

/* socialMedia */
.socialMedia {
  text-align: center;
  padding-top: 10px;
  padding-right: 10px;
}

.socialMedia a {
  margin-right: 15px;
}

/* Camps */
.camps h1 {
    text-align: center;
    color: #e67817;
}

.camps h2 {
    text-align: left;
    color: #e67817;
}

.campArticle {
  float: left;
}

/* Family member navigations */
.family-member-navigations-section img {
  width: 145px;
  height: 145px;
  border-radius: 100%;
  vertical-align: middle;
}

.family-member-navigations-section button {
  background: transparent;
}

.family-member-navigations-section > p {
  padding-bottom: 20px;
  padding-left: 10px;
}

.family-member-navigations-section > h3 {
  padding-top: 25px;
  text-align: center;
  color: #e67817;
}

/* Bottom navigation images */
.bottom-navigation-images  img {
  width: 200px;
  height: 200px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.bottom-navigation-images button {
  background: transparent;
}


/* Application form */

#applicationForm {
  padding: 10px;
  width: 100%;
  display: grid;
}

#applicationForm h4 {
  float: left;
}

#applicationForm button {
  margin-top: 10px;
}

/* Selected item from dropdown in multiselect  */
#multiselectContainerReact span {
   white-space: normal !important; /* break long line into 2 lines */

   padding-right: 10px;
}

/* Dropdown option in multiselect */
#multiselectContainerReact li {
  list-style-type: none;
}

.toast-container {
   width: 440px !important;
}

.termsLink {
  padding-left: 20px;
}

.privateDocs .orange {
  color: #e67817 !important;
  text-transform: none !important;
}

/* Original was too big ->
 resized to 707 x 1000 px
 Podzimni chytani resize -> Edit -> Resize -> 800 x 1000 px
 autumn_popup_desktop.png
 */
.popup {
  z-index: 9999;
  background-color: orange;
  background-image: url("../images/popup/Silvester_2024-desktop.jpg");
  transform: scale(0.5);

  position: fixed;
  top: 0%;
  left: 25%;
  width: 940px;
  height: 788px;
}

.popup-button {
  position: absolute;
  top: 4px;
  left: 35%;
  width: 250px;
}

.popup-close-button {
  position: absolute;
     background: black;
     color: white;
     top: 0px;
     right: 0px;
}

.popup-fixed-size {
  z-index: 9998;
  background-color: orange;
  background-image: url("../images/popup/bannery-J4KSPORTS.jpg");

  position: fixed;
  top: 10%;
  left: 30%;
  width: 539px;
  height: 400px;
}

.popup-fixed-size-button {
  position: absolute;
  top: 4px !important;
  left: 150px !important;
  width: 150px !important;
}



/* resized to 350 x 495 px
autumn_popup_mobile.png
Silvester_2024-mobile.jpg: resized to 55% -> used exact pixels in .popup widht and height!
+ added left: -10% !important; in in .popup
*/
@media (max-width: 900px) {

  .popup {
    background-image: url("../images/popup/Silvester_2024-mobile.jpg") !important;
    width: 517px !important;
    height: 434px !important;
    left: -10% !important;
    top: 17%;
  }

  .popup-button {
    position: absolute;
    top: 4px !important;
    left: 20px !important;
    width: 150px !important;
  }

  .popup-fixed-size {
    background-image: url("../images/popup/bannery-J4KSPORTS.jpg") !important;
    width: 469px !important;
    height: 400px !important;
    transform: scale(0.5);
    left: -15% !important;
  }
}
