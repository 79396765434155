.burger-menu,
.burger-menu.open {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  text-align: center;

  top: 7px;
  right: 20px;

  z-index: 9999;
  background: #fff;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.burger-menu .bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: #222;/*#e67817; */
  margin: 4px 0;
  transition: 0.4s;
}

.burger-menu.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
}

.burger-menu.open .bar2 {
  opacity: 0;
}

.burger-menu.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

/* Menu - for burget icon*/
.menu {
  width: 100%;
  display: block;
  padding: 0px;
}


.menu a {
	color: #eee;
	text-decoration: none;
  font-size: 1.2em;
}

.menu a:hover {
  font-weight: bolder;
}

header ul {
   list-style-type: none;
}

.navigation {
  padding-left: 15px;
  padding-top: 15px;
  text-transform: uppercase;
}

.main-navigation a {
 padding: 0px 10px;
 text-transform: uppercase;
 font-size: 1.1em;
 color: white;
 text-decoration: none;
 text-shadow: 2px 2px 4px #000000;
 cursor: pointer;
}

.main-navigation li {
  display: inline-block;
  list-style: none;
}
